import React from "react";
import PropTypes from "prop-types";

import Link from "next/link";
import Action from "./action";
import Image from "next/image";

class StandardContent extends React.Component {
  renderTitle(content, config) {
    if (config.title && config.title === "h2") {
      return <h2 className='StandardContent-title'>{content.title}</h2>;
    }

    if (content.icon) {
      return (
        <h4 className='StandardContent-title StandardContent-title--withIcon'>
          <img className='StandardContent-icon svg' src={content.icon} />
          <span>{content.title}</span>
        </h4>
      );
    } else {
      return <h3 className='StandardContent-title'>{content.title}</h3>;
    }
  }

  renderImage(image, url) {
    if (url) {
      return (
        <div className='StandardContent-image-wrap'>
          <Link href={url}>
            <a>
              <Image
                {...image}
                placeholder="blur"
                width={image.width}
                height={image.height}
                blurDataURL={image.blurDataURL}
                layout="responsive"
                className='StandardContent-image'
              />
            </a>
          </Link>
        </div>
      );
    } else {
      return (
        <div className='StandardContent-image-wrap'>
          <Image
            {...image}
            width={image.width}
            height={image.height}
            placeholder="blur"
            blurDataURL={image.blurDataURL}
            layout="responsive"
            className='StandardContent-image'
          />
        </div>
      );
    }
  }

  render() {
    const { config, content } = this.props.block;

    const classNames = config["className"]
      ? `${config["className"]} StandardContent`
      : "StandardContent";
    const url =
      content.actions && content.actions.length > 0
        ? content.actions[0].url
        : false;

    return (
      <section className={classNames}>
        {content.image &&
          content.image.src &&
          this.renderImage(content.image, url)}

        {this.renderTitle(content, config)}

        {content.description && (
          <p
            dangerouslySetInnerHTML={{
              __html: content.description,
            }}
          />
        )}

        {content.actions && (
          <div className='StandardContent-actions'>
            {content.actions.map(function (action, key) {
              return (
                <div key={key}>
                  <Action action={action} />
                </div>
              );
            })}
          </div>
        )}
      </section>
    );
  }
}

StandardContent.propTypes = {
  block: PropTypes.shape({
    content: PropTypes.shape({}),
    config: PropTypes.shape({}),
  }),
};

export default StandardContent;
